import styles from "./HiveSuccess.module.css";
import { Back, Helper, } from "../components";
import { BackArrow, } from "../icons";
import { Oval } from  'react-loader-spinner';
import { useParams, useNavigate, useSearchParams, } from "react-router-dom";
import React, { useEffect, useState, useRef, } from "react";


const HiveSuccess = () => {
	const navigate = useNavigate();
	const myActivities = useRef(null);
	const [searchParams, setSearchParams] = useSearchParams();
    const leadId = searchParams.get('leadId') || '';
	const [lead, setLead] = useState({});
	const [group, setGroup] = useState({});
	const [invalidLeadId, setInvalidLeadId] = useState(false);
	const [scrollbarLeft, setScrollbarLeft] = useState(0);
	const [activitiesCount, setActivitiesCount] = useState(0);
	const { height, width } = Helper.getWindowDimensions();


	const handleBytesScroll = () => {
       const totalScrollPossible = ((width * 0.5) - 15) * (activitiesCount  - 2);
       let left = (document.getElementById('activitiesContainer').scrollLeft / totalScrollPossible) * 100;
       left = left >= 100 ? 100 : left;
       const substract = left >= 80 ? ' - ' + ((left - 80) * 1.75) + 'px' : '';
       setScrollbarLeft('calc(' + left + '%' + substract  + ')');
    };

    useEffect(() => {
      if (myActivities && myActivities.current && activitiesCount === 0) {
        setActivitiesCount(myActivities.current.children.length);
      }
	});

	const getAnalyticsCategory = () => {
		try {
	      return `HiveSuccess_${lead.group.title.split(' ').join('_')}`;
	    } catch(err) {
	      return 'HiveSuccess';
	    }
	};

	const trackAnalytics = (action, label) => {
		Helper.trackAnalytics(getAnalyticsCategory(), action, label);
	};
 
	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/leads/${leadId}/details`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) {
	        		setLead(res.data);
	        		setGroup(res.group);
	        	} else setInvalidLeadId(true);
	     	})
	     	.catch((err) => {
	     		setInvalidLeadId(true);
	        	console.log(err.message);
	     	});
	}, {});

  	return(
   		<div className={styles.hiveSuccess}>
   			{invalidLeadId && 
		        <h1 style={{ textAlign: 'center', margin: 0, padding: '80px 50px'}}>Apologies! XPHive does not have this lead in the system.</h1>
		    }

		    {(!invalidLeadId && Object.keys(lead).length == 0) ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		    /> : '' }

		    { Object.keys(lead).length != 0 ?
		        <div>
			        <div>
			        	<i onClick={(e) => { trackAnalytics('Click_Back_from_hive_success', 'Hive_Success_Page'); navigate('/'); }} className={styles.backIcon}><span style={{position: 'absolute', left: '11px', top: '11px'}}><BackArrow /></span></i>
			        	
          				<h1 className={styles.pageHeading}>Payment Success</h1>

          				<div>
          					<div className={styles.topSection}>
			        			<h2>{group.title}</h2>
			        			<p>{group.description}</p>
			        		</div>

			        		<div>
				        		<div ref={myActivities} onScroll={handleBytesScroll} id="activitiesContainer" className={styles.activitiesContainerParent}>
					        		{group.activities.map((activity) => {
					        			if (activity.name in lead.details) {
					        				return(<div className={styles.activityQue}>
					          					{activity.data.map((activityData, ind) => {
					          						if (lead.details[activity.name] && lead.details[activity.name].includes(activityData.title)) {
											            return(<div className={styles.activity}>
											              <div className={styles.activityImgWrap}>
											                <img src={activityData.image} />
											                {activityData.price && <label>₹{activityData.price.toLocaleString()}</label>}
											              </div>
											              <label className={styles.activityTitle}>{activityData.title}</label>
											              <label className={styles.activitySubTitle}>{activityData.subtitle}</label>
											              <p className={styles.activityDesc}>{activityData.description}</p>
											            </div>);
										            }
										            return null;
										          })}
									        </div>)
					        			}

					        			return null;
								    })}
				          		</div>
			          			{activitiesCount > 2 && <div className={styles.scrollWrap}>
						          <span className={styles.scrollLine}></span>
						          <span className={styles.scrollBar} style={{'left': scrollbarLeft}}></span>
						        </div>}
					        </div>
          				</div>

		        		<div className={styles.priceDetails}>
			        		<ul>
			        			<li>
			        				Token Amount (PAID)
			        				<p>₹500 x 1<span>₹500</span></p>
			        				<span className={styles.finalAmount}>{`₹500`}</span>
			        			</li>

			        			<li>
			        				Estimated Trip Cost
			        				<span style={{marginTop: '3px'}} className={styles.finalAmount}>{lead.details.price ? `₹${lead.details.price.toLocaleString()}` : '-'}</span>
			        			</li>

			        			<li className={styles.priceNote}>
			        				Note: The token amount will give you the opportunity to start planning for your experience with the creator, but the final amount will be based on your preferences.
			        			</li>
			        		</ul>
			        	</div>

			        	{group.whatsappLink && <a className={styles.groupJoinBtn} target="_blank" href={group.whatsappLink}><img src="/wa-v1.png" />Click here to join</a>}
			        </div>
		        </div>
		    : ''}
   		</div>
  	);
};

export default HiveSuccess;
