import styles from "./Blogs.module.css";
import React, { useEffect, useState, } from "react";
import { Helper, Favourite, } from "../components";
import { useNavigate } from "react-router-dom";


const Blogs = (props) => {
	const navigate = useNavigate();
	const [blogs, setBlogs] = useState([]);

	const routeChange = (e, slug, url) => {
		Helper.trackAnalytics(props.analyticsCategory || 'Blogs', `Click_${Helper.titleCase(slug.split('/')[slug.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate_Blog`);
   		navigate(url);
  	}

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/blogs?vibeIds=${[]}&active=true`)
	      .then((response) => response.json())
	      .then((res) => {
	      	const blogsData = res.data.filter(data => data['_id'] !== props.excludeId);
	       	setBlogs(blogsData);
	      })
	      .catch((err) => {
	        console.log(err.message);
	      });
	   }, {});

  	return (
	    <div className={styles.blogs}>
			<h2 style={{color: props.color}} className={styles.blogsHeading}>{props.heading || 'Read'}</h2>
			<div className={styles.blogContainers} style={{...props.containerStyle}}>
				{blogs.map((blog, i) => {
				  return(
				    <div className={styles.blog} onClick={event => routeChange(event, blog.slug, `/blogs/${blog.slug}`)} key={i}>
				      <div style={{position: 'relative'}}>
				        <img src={blog.image} />
				        <Favourite analyticsCategory={props.analyticsCategory || 'Blogs'} id={blog._id} circleWidth={'40'} circleHeight={'40'} storage={'blog'} style={{ position: 'absolute', top: '10px', right: '10px' }} />
				      </div>
				      <h4 className={styles.blogTitle}>{blog.title}</h4>
				    </div>
				  );
				})}
			</div>
		</div>
  	);
}

export default Blogs;
