import styles from "./ContactUs.module.css";
import React, { useEffect, useState, } from "react";
import { Helper,} from "../components";


const ContactUs = (props) => {
  const [leadSubmitted, setLeadSubmitted] = useState(Helper.storage.getItem('contactUsLeads') || false);
  const [message, setMessage] = useState('');
  const [emptyMsg, setEmptyMsg] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [userPhone, setUserPhone] = useState('');
  const [invalidPhone, setInvalidPhone] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!message) setEmptyMsg(true);
    if (userPhone.length != 10) setInvalidPhone(true);

    if (!submittingForm && userPhone && !invalidPhone  && message && !emptyMsg) {
      Helper.trackAnalytics('ContactUs', 'Click_Submit_details', userPhone);
      setSubmittingForm(true);
      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
          message: message,
          phone: userPhone,
          type: 'ContactUs',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false)
           if (data.status == 'success') setLeads();
        })
        .catch((err) => {
          setSubmittingForm(false)
          console.log(err.message);
        });
    }
  };

  const setLeads = () => {
    setLeadSubmitted(true);
    Helper.storage.setItem('contactUsLeads', true);
  };

  const onChangeMessage = (e) => {
    setEmptyMsg(false);
    setMessage(e.target.value);
  };

  const onChangeUserPhone = (e) => {
    setInvalidPhone(false)
    if (e.target.value.length < 11)
      setUserPhone(e.target.value);
  };

  return (
    <div className={styles.contactUs} style={{...props.style}}>
      <h3>{props.heading || 'Want to know more?'}</h3>
      <h4>{props.subHeading || 'reach out to us...'}</h4>

      {!leadSubmitted &&
        <form onSubmit={handleSubmit}>
          <div>
            <input style={{background: props.bgColor}} className={invalidPhone ? styles.invalid : styles.valid} type="number" placeholder="Phone No. (+91)" value={userPhone} onChange={e => onChangeUserPhone(e)} />
          </div>
          <div>
            <textarea style={{background: props.bgColor}} className={emptyMsg ? styles.invalid : styles.valid} rows="3" placeholder="Write your message here.." value={message} onChange={e => onChangeMessage(e)} />
          </div>
          <button type="submit">Send</button>
        </form>
      }

      {leadSubmitted && 
        <div>
          <h6 className={styles.successMsg}>We hear you friend! Our reply is racing to your inbox!</h6>
        </div>
      }
    </div>
  );
}

export default ContactUs;
